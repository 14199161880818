const username = localStorage.getItem('username');

var menu = [];

menu.push({ id: 0, text: "Home", icon: "home", url: "/", home: true });
menu.push({ id: 1, text: "-" });
menu.push({ id: 2, text: "La tua collezione", icon: "train", url: "/modelli/collezione", home: false });
menu.push({ id: 3, text: "Modelli condivisi", icon: "account-multiple", url: "/modelli/condivisi", home: false });
menu.push({ id: 4, text: "Modelli venduti", icon: "tag", url: "/modelli/venduti", home: false });
menu.push({ id: 5, text: "Lista dei desideri", icon: "heart", url: "/modelli/wishlist", home: false });
menu.push({ id: 6, text: "-" });
menu.push({ id: 7, text: "Convogli", icon: "train-car-passenger-door", url: "/convogli", home: false });
menu.push({ id: 8, text: "-" });
menu.push({ id: 9, text: "Interventi", icon: "toolbox", url: "/interventi", home: false });
menu.push({ id: 10, text: "-" });
menu.push({ id: 11, text: "Feedback", icon: "comment-text", url: "/feedback", home: false });
menu.push({ id: 12, text: "-" });
menu.push({ id: 13, text: "Condivisioni",icon: "account-multiple", subMenu: [
  { id: 101, text: "Modelli", icon: "train", url: "/condivisioni/modelli" },
  { id: 102, text: "Convogli", icon: "train-car-passenger-door", url: "/condivisioni/convogli" },
]});

if (username === "stefano.lanfranco") {
  menu.push({ id: 14, text: "-" });
  menu.push({ id: 15, text: "Configurazioni",icon: "cogs", subMenu: [
    { id: 201, text: "Alimentazione", icon: "current-ac", url: "/setting/alimentazione" },
    { id: 202, text: "Motori", icon: "engine", url: "/setting/motori" },
    { id: 203, text: "Condizioni", icon: "new-box", url: "/setting/condizioni" },
    { id: 204, text: "Decoder", icon: "sack-percent", url: "/setting/decoder" },
    { id: 205, text: "Classi rotabili", icon: "train-variant", url: "/setting/rotabili/classi" },
    { id: 206, text: "Tipi rotabili", icon: "train-car-passenger-variant", url: "/setting/rotabili/tipi" },   
    { id: 207, text: "Ferrovie", icon: "fence", url: "/setting/ferrovie" },
    { id: 208, text: "Funzioni", icon: "function", url: "/setting/funzioni" },
    { id: 209, text: "FN", icon: "function-variant", url: "/setting/fn" },
    { id: 210, text: "Produttori", icon: "factory", url: "/setting/produttori" },
    { id: 211, text: "Fornitori", icon: "factory", url: "/setting/fornitori" },
    { id: 212, text: "Nazioni", icon: "flag-variant", url: "/setting/nazioni" },
    { id: 213, text: "Scale", icon: "relative-scale", url: "/setting/scale" },
    { id: 214, text: "Epoche", icon: "history", url: "/setting/epoche" },
    { id: 215, text: "Utenti", icon: "account-group", url: "/setting/utenti" },
  ]});
}

menu.push({ id: 16, text: "-" });
menu.push({ id: 17, text: "Logout", icon: "logout", url: "/logout", home: false });

export default menu;