import React, { useState, useEffect } from 'react';
import api from '../lib/api';
import { nullZLSTabella } from './../lib/dati';
import Loader from './../components/Loader';
import useNotification from './../components/useNotification';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ContextCommand from '../components/ContextCommand';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormSelect from '../components/FormSelect';
import FormTextField from '../components/FormTextField';

export default function InterventiDettaglio({setTitle, setContextCommands}) {
	const { id } = useParams();
	const [ isLoaderOpen, setLoaderOpen ] = useState(false);
	const { pushNotification } = useNotification();
	const { control, handleSubmit, /*watch, trigger, getValues,*/ setValue, reset, /*formState: { errors }*/ } = useForm({mode: 'all'});
	const navigate = useNavigate();
	const [tipiModelli, setTipiModelli] = useState([]);

	useEffect(() => {
		setLoaderOpen(true);
  	}, []);

	useEffect(() => {
		api.get('/modelli/elenco').then(data=>{
			setTipiModelli(data);
		});
  	}, []);

	useEffect(()=>{
		if (id==='new') {
			setValue('_action', 'n');
			setLoaderOpen(false);
		} else {
			api.get(`/interventi/${id}`).then(data=>{
				if (data.length!==1) {
					alert('Errore nel caricamento dei dati');
						navigate("/interventi/");
				} else {
					const intervento = data[0];
					intervento._action="e";
					reset(intervento);
					setTitle("Dettaglio dell'intervento");
				}
				setLoaderOpen(false);
			});
		}
	}, [id, setValue, setTitle, reset]);

	useEffect(() => {
		const salva = data => {
			var array = [];
			array.push(data);
			const json = nullZLSTabella(array);
			api.post("/interventi", json).then(r=>{
				pushNotification("Dati salvati", "success");
				navigate("/interventi/"+r);
			}, error=>{
				pushNotification("Errore nel salvataggio dell'intervento", "error");
			});
		}
		const contextCommands=[];
		contextCommands.push(<ContextCommand key="1" icon="backspace" onClick={ ()=>navigate("/interventi") }>Indietro</ContextCommand>);
		contextCommands.push(<ContextCommand key="2" icon="floppy" onClick={ handleSubmit(data=>salva(data)) }>Salva</ContextCommand>);
		setContextCommands(contextCommands);
	}, [setContextCommands, handleSubmit, navigate, pushNotification] );

	return (
		<Box sx={{ height: '100%', width: '100%' }}>
			<Loader open={isLoaderOpen} />
			<Grid container spacing={2}>
				<FormTextField xs={4} md={2} size="small" control={control} name="id" label="ID" type="text" disabled/>
				<FormSelect xs={8} md={10} size="small" control={control} name='modello' label="Modello" options={tipiModelli} valueField='id' labelField='descrizione' />

				<FormTextField xs={12} md={12} size="small" placeholder="La descrizione dell'intervento" control={control} name="descrizione" label="Descrizione" type="text"/>
				
				<FormTextField xs={6} md={6} size="small" control={control} name="data" label="Data" type="date"/>
				<FormTextField xs={6} md={6} size="small" control={control} name="costo" label="Costo" type="number"/>
			</Grid>
		</Box>
	);
}
