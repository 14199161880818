import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import ContextCommand from '../components/ContextCommand';
import useNotification from './../components/useNotification';
import api from '../lib/api';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { DateTime } from "luxon";

export default function Interventi({setTitle, setContextCommands}) {
	const [ rows, setRows ] = useState([]);
	const [ selectedIDs, setSelectedIDs ] = useState([]);
	const navigate = useNavigate();
	const { pushNotification } = useNotification();

	const currencyFormatter = new Intl.NumberFormat('it-IT', {
		style: 'currency',
		currency: 'EUR',
	});

	const columns=[
		// { field: 'id', headerName: 'ID', width: 90, type:'number' },
		{ field: 'descrizioneModello', headerName: 'Modello', flex:true },
		{ field: 'descrizione', headerName: 'Descrizione', flex:true},
		{ field: 'data', headerName: 'Data', width: 100, valueGetter: params => DateTime.fromSQL(params.value).toFormat('dd/MM/yyyy') },
		{ field: 'costo', headerName: 'Costo', width: 100, type:'number', valueGetter: params => currencyFormatter.format(Number(params.value)) },
	]

	const caricaRows = () => {
		api.get("/interventi/elenco").then(data=>{
		  setRows(data);
		}, error=>{
		  console.log("Impossibile caricare i dati", "error");
		});
	}

	useEffect(() => {
	    setTitle('Interventi');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaRows();
	}, [setTitle, setContextCommands]);

	const cancella = useCallback(() => {
		selectedIDs.map(function(id, row){
			api.delete("/interventi/"+id, []).then(r=>{
				pushNotification("Dati cancellati", "success");
				caricaRows();
				navigate("/interventi/");
			}, error=>{
				pushNotification("Errore nella cancellazione", "error");
			});
			return true;
		})
		setSelectedIDs([]);
	}, [selectedIDs, pushNotification, navigate]);

	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="plus" onClick={ ()=>navigate("/interventi/new") }>Aggiungi</ContextCommand>,
			<ContextCommand key="2" icon="delete" onClick={ cancella }>Cancella</ContextCommand>,
		]);
	}, [setContextCommands, navigate, cancella] );

	const rowDettaglio = (params) => {
		navigate(encodeURI("/interventi/"+params.id));
	};

	const handleSelectionChange = (selection) => {
		setSelectedIDs(selection);
	};

	return (
		<Box
			sx={{ height: '100%', width: '100%' }}
			noValidate
			autoComplete="off"
		>
			<p>
				Qui hai la possibilità di inserire gli interventi dei tuoi modelli in modo semplice e rapido.
			</p>
			<DataGrid
				rows={rows}
				columns={columns}
				initialState={{
					pagination: {
					paginationModel: {
						pageSize: 15,
					},
					},
				}}
				pageSizeOptions={[15]}
				checkboxSelection
				onRowSelectionModelChange={handleSelectionChange}
				disableRowSelectionOnClick
				onRowClick={rowDettaglio}
			/>
		</Box>
		);
}
