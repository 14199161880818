import React, { useState, useEffect } from 'react';
import api from '../lib/api';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
// import useNotification from './../components/useNotification';
import FormTextField from '../components/FormTextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

export default function Feedback({setTitle, setContextCommands}) {
	// const { pushNotification } = useNotification();
	const [utente, setUtente] = useState("");
	const { control, setValue } = useForm({mode: 'all', defaultValues: { nome: "", email: "" }});

	useEffect(() => {
	    setTitle('Feedback');
		const contextCommands=[];
		setContextCommands(contextCommands);
  	}, [setTitle, setContextCommands]);

	useEffect(() => {
		const username = localStorage.getItem('username');
		api.get('/utenti').then(data=>{
			data.find((element) => {
				if (element.username === username) {
					setUtente(element);
					setValue("nome", element.nome+" "+element.cognome);
					setValue("email", element.email);
				}
				return false;
			  })
		});
  	}, []);

	return (
		<Box sx={{ height: '100%', width: '100%' }}>
			<h2>Grazie {utente.nome} per dedicarci del tempo per fornire il tuo prezioso feedback!</h2>
			<p>Siamo entusiasti di ricevere i tuoi commenti per migliorare continuamente la nostra esperienza utente. La tua opinione è fondamentale per aiutarci a fornire un servizio sempre migliore. Per favore, condividi con noi la tua esperienza e le tue opinioni attraverso il modulo di feedback qui sotto.</p>
			<p>&nbsp;</p>
			<Grid container spacing={2}>
				<FormTextField xs={12} md={6} size="small" control={control} name="nome" label="Nome e cognome" type="text"/>
				<FormTextField xs={12} md={6} size="small" control={control} name="email" label="Indirizzo email" type="text"/>
				<FormTextField xs={12} md={12} size="small" control={control} name="body01" label="Esperienza generale" multiline type="text" helperText="Descrivi la tua impressione generale del nostro servizio. Se c'è qualcosa che ti ha particolarmente colpito positivamente o negativamente, ti preghiamo di condividerlo con noi."/>
				<FormTextField xs={12} md={12} size="small" control={control} name="body02" label="Facilità d'uso" multiline type="text" helperText="Valuta la facilità con cui hai navigato sul nostro sito/web app. Ciò che ti è piaciuto o ti ha creato difficoltà?"/>
				<FormTextField xs={12} md={12} size="small" control={control} name="body03" label="Progettazione e layout" multiline type="text" helperText="Cosa ne pensi del design e dell'aspetto generale della nostra piattaforma? Ci sono suggerimenti per migliorare l'aspetto visivo?"/>
				<FormTextField xs={12} md={12} size="small" control={control} name="body04" label="Funzionalità" multiline type="text" helperText="Le funzionalità offerte sono state utili ed efficaci per le tue esigenze? C'è qualcosa che vorresti vedere aggiunto o migliorato?"/>
				<FormTextField xs={12} md={12} size="small" control={control} name="body05" label="Prestazioni" multiline type="text" helperText="Hai riscontrato problemi di caricamento lenti o altre difficoltà di prestazioni? Se sì, ti preghiamo di fornirci maggiori dettagli."/>
				<FormTextField xs={12} md={12} size="small" control={control} name="body06" label="Servizio clienti" multiline type="text" helperText="Se hai avuto interazioni con il nostro servizio clienti, descrivi l'esperienza. Se non l'hai ancora fatto, ti preghiamo di farci sapere come possiamo assisterti meglio in futuro."/>
				<FormTextField xs={12} md={12} size="small" control={control} name="body07" label="Suggerimenti di miglioramento" multiline type="text" helperText="Hai idee o suggerimenti su come possiamo rendere il nostro servizio ancora migliore? Ci piacerebbe sentirli!"/>
				<FormTextField xs={12} md={12} size="small" control={control} name="body08" label="Commenti aggiuntivi" multiline type="text" helperText="Se ci sono ulteriori commenti, domande o osservazioni che desideri condividere, sentiti libero di farlo qui."/>
			</Grid>
			<p>&nbsp;</p>
			<Grid style={{textAlign: 'center'}}>
				<Button variant="contained" endIcon={<SendIcon />} size="large">
					INVIA IL FEEDBACK
				</Button>
			</Grid>
			<p>&nbsp;</p>
			<p>Grazie ancora per il tuo tempo e il tuo contributo! Il tuo feedback è estremamente prezioso per noi. Ci assicureremo che ogni commento sarà attentamente valutato e preso in considerazione per sviluppare una piattaforma sempre più soddisfacente per te e per tutti i nostri utenti.</p>
			<p>Ci impegniamo a fornire il miglior servizio possibile e non vediamo l'ora di accoglierti nuovamente nella nostra community.</p>
			<p>&nbsp;</p>
			<p style={{textAlign: 'center'}}><i>Il team di Model railway management</i></p>
		</Box>
		);
}
