import React, { useEffect, useState } from 'react';
import api from '../../lib/api';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

export default function Ferrovie({setTitle, setContextCommands}) {
  const [ rows, setRows ] = useState([]);

  const columns=[
    { field: 'id', headerName: 'ID', width: 90, type:'number' },
	{ field: 'descrizioneNazione', headerName: 'Nazione', width: 200 },
	{ field: 'sigla', headerName: 'Sigla', width: 160 },
	{ field: 'estesa', headerName: 'Ferrovia', flex:true },
  ]

  const caricaRows = () => {
    api.get("/ferrovie").then(data=>{
      setRows(data);
    }, error=>{
      console.log("Impossibile caricare i dati", "error");
    });
  } 

	useEffect(() => {
	  setTitle('Ferrovie');
		const contextCommands=[];
		setContextCommands(contextCommands);
    caricaRows();
  }, [setTitle, setContextCommands]);

	return (
		<Box sx={{ height: '100%', width: '100%' }}>
			<p>
			In questa pagina è presente un elenco della varie tipologie di ferrovie.
			</p>
			<DataGrid
			rows={rows}
			columns={columns}
			initialState={{
				pagination: {
				paginationModel: {
					pageSize: 15,
				},
				},
			}}
			pageSizeOptions={[15]}
			disableRowSelectionOnClick
			/>
		</Box>
		);
}