import React, { useEffect, useState } from 'react';
import api from '../../lib/api';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

export default function Utenti({setTitle, setContextCommands}) {
  const [ rows, setRows ] = useState([]);

  const columns=[
    { field: 'id', headerName: 'ID', width: 90, type:'number' },
    { field: 'username', headerName: 'Username', flex:true },
	{ field: 'password', headerName: 'Password', flex:true },
	{ field: 'nome', headerName: 'Nome', flex:true },
	{ field: 'cognome', headerName: 'Cognome', flex:true },
	{ field: 'email', headerName: 'email', flex:true },
	{ field: 'ultimaConnessione', headerName: 'Ultima connessione', flex:true },
	{ field: 'ultimoIP', headerName: 'Ultimo IP', flex:true },
	{ field: 'ultimoBrowser', headerName: 'Ultimo browser', flex:true },
	{ field: 'creato', headerName: 'Creato il', flex:true },
	{ field: 'modificato', headerName: 'Modificato il', flex:true },
  ]

  const caricaRows = () => {
    api.get("/utenti").then(data=>{
      setRows(data);
    }, error=>{
      console.log("Impossibile caricare i dati", "error");
    });
  } 

	useEffect(() => {
	  setTitle('Utenti');
		const contextCommands=[];
		setContextCommands(contextCommands);
    caricaRows();
  }, [setTitle, setContextCommands]);

	return (
		<Box sx={{ height: '100%', width: '100%' }}>
			<p>
			In questa pagina è presente un elenco dei vari utenti.
			</p>
			<DataGrid
			rows={rows}
			columns={columns}
			initialState={{
				pagination: {
				paginationModel: {
					pageSize: 15,
				},
				},
			}}
			pageSizeOptions={[15]}
			disableRowSelectionOnClick
			/>
		</Box>
		);
}