import React from 'react';
import { Routes, Route } from "react-router-dom";
import NotFound from './errors/NotFound'
import Login from './Login';
import Logout from './Logout';
import Home from './Home';
import Modelli from './Modelli';
import ModelliDettaglio from './ModelliDettaglio';
import ModelliCondivisi from './ModelliCondivisi';
import ModelliCondivisiDettaglio from './ModelliCondivisiDettaglio';
import Convogli from './Convogli';
import ConvogliDettaglio from './ConvogliDettaglio';
import Interventi from './Interventi';
import InterventiDettaglio from './InterventiDettaglio';
import Feedback from './Feedback';
import Alimentazione from './setting/Alimentazione';
import Motori from './setting/Motori';
import Condizioni from './setting/Condizioni';
import Decoder from './setting/Decoder';
import ClassiRotabili from './setting/ClassiRotabili';
import TipiRotabili from './setting/TipiRotabili';
import Ferrovie from './setting/Ferrovie';
import Funzioni from './setting/Funzioni';
import FN from './setting/FN';
import Produttori from './setting/Produttori';
import Fornitori from './setting/Fornitori';
import Nazioni from './setting/Nazioni';
import Scale from './setting/Scale';
import Epoche from './setting/Epoche';
import Utenti from './setting/Utenti';

export default function MyRoutes({setTitle, setContextCommands}) {
  const defaultProps = {setTitle, setContextCommands};
  return (
    <Routes>
      <Route path="/" element={ <Home {...defaultProps} /> } />
      <Route path="/login" element={ <Login {...defaultProps} /> } />
      <Route path="/modelli/collezione" element={ <Modelli tipo="collezione" {...defaultProps} /> } />
      <Route path="/modelli/collezione/:id" element={ <ModelliDettaglio tipo="collezione" {...defaultProps} /> } />
      <Route path="/modelli/venduti" element={ <Modelli tipo="venduti" {...defaultProps} /> } />
      <Route path="/modelli/venduti/:id" element={ <ModelliDettaglio tipo="venduti" {...defaultProps} /> } />
      <Route path="/modelli/wishlist" element={ <Modelli tipo="wishlist" {...defaultProps} /> } />
      <Route path="/modelli/wishlist/:id" element={ <ModelliDettaglio tipo="wishlist" {...defaultProps} /> } />
      <Route path="/modelli/condivisi" element={ <Modelli tipo="condivisi" {...defaultProps} /> } />
      <Route path="/modelli/condivisi/:id" element={ <ModelliDettaglio tipo="condivisi" {...defaultProps} /> } />
      <Route path="/convogli" element={ <Convogli {...defaultProps} /> } />
      <Route path="/convogli/:id" element={ <ConvogliDettaglio {...defaultProps} /> } />
      <Route path="/interventi" element={ <Interventi {...defaultProps} /> } />
      <Route path="/interventi/:id" element={ <InterventiDettaglio {...defaultProps} /> } />

      <Route path="/condivisioni/modelli" element={ <ModelliCondivisi {...defaultProps} /> } />
      <Route path="/condivisioni/modelli/:id" element={ <ModelliCondivisiDettaglio {...defaultProps} /> } />

      <Route path="/setting/alimentazione" element={ <Alimentazione {...defaultProps} /> } />
      <Route path="/setting/motori" element={ <Motori {...defaultProps} /> } />
      <Route path="/setting/condizioni" element={ <Condizioni {...defaultProps} /> } />
      <Route path="/setting/decoder" element={ <Decoder {...defaultProps} /> } />
      <Route path="/setting/rotabili/classi" element={ <ClassiRotabili {...defaultProps} /> } />
      <Route path="/setting/rotabili/tipi" element={ <TipiRotabili {...defaultProps} /> } />
      <Route path="/setting/ferrovie" element={ <Ferrovie {...defaultProps} /> } />
      <Route path="/setting/funzioni" element={ <Funzioni {...defaultProps} /> } />
      <Route path="/setting/fn" element={ <FN {...defaultProps} /> } />
      <Route path="/setting/produttori" element={ <Produttori {...defaultProps} /> } />
      <Route path="/setting/fornitori" element={ <Fornitori {...defaultProps} /> } />
      <Route path="/setting/nazioni" element={ <Nazioni {...defaultProps} /> } />
      <Route path="/setting/scale" element={ <Scale {...defaultProps} /> } />
      <Route path="/setting/epoche" element={ <Epoche {...defaultProps} /> } />
      <Route path="/setting/utenti" element={ <Utenti {...defaultProps} /> } />
      <Route path="/feedback" element={ <Feedback {...defaultProps} /> } />
      <Route path="/logout" element={ <Logout {...defaultProps} /> } />  
      <Route path="*" element={ <NotFound {...defaultProps} /> } />
    </Routes>
  )
}
