import React, { useState, useEffect, useCallback } from 'react';
import api from '../lib/api';
import { nullZLSTabella } from './../lib/dati';
import Loader from './../components/Loader';
import useNotification from './../components/useNotification';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import { DateTime } from "luxon";
import ContextCommand from '../components/ContextCommand';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormCheckbox from '../components/FormCheckbox';
import FormSelect from '../components/FormSelect';
import FormTextField from '../components/FormTextField';
import TrainOutlinedIcon from '@mui/icons-material/TrainOutlined';
import TrainIcon from '@mui/icons-material/Train';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PeopleIcon from '@mui/icons-material/People';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import SellIcon from '@mui/icons-material/Sell';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function ModelliDettaglio({tipo, setTitle, setContextCommands}) {
	const { id } = useParams();
	const [ isLoaderOpen, setLoaderOpen ] = useState(false);
	const { pushNotification } = useNotification();
	const { control, handleSubmit, /*watch, trigger, getValues,*/ setValue, reset, /*formState: { errors }*/ } = useForm({mode: 'all'});
	const navigate = useNavigate();
	const [idNazione, setIdNazione] = useState("");
	const [tipiClassi, setTipiClassi] = useState([]);
	const [tipiRotabili, setTipiRotabili] = useState([]);
	const [tipiProduttori, setTipiProduttori] = useState([]);
	const [tipiScale, setTipiScale] = useState([]);
	const [tipiAlimentazioni, setTipiAlimentazioni] = useState([]);
	const [tipiNazioni, setTipiNazioni] = useState([]);
	const [tipiFerrovie, setTipiFerrovie] = useState([]);
	const [tipiEpoche, setTipiEpoche] = useState([]);
	const [tipiFornitori, setTipiFornitori] = useState([]);
	const [tipiCondizioni, setTipiCondizioni] = useState([]);
	const [tipiMotori, setTipMotori] = useState([]);
	const [tipiDecoder, setTipiDecoder] = useState([]);

	function srcset(image, size, rows = 1, cols = 1) {
		return {
		  src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
		  srcSet: `${image}?w=${size * cols}&h=${
			size * rows
		  }&fit=crop&auto=format&dpr=2 2x`,
		};
	  }

	const itemData = [
		{
		  img: '/var/www/vhosts/apertoladomenica.com/uploads/1/1/1.jpg',
		},
		{
		  img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
		},
		{
		  img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
		},
		{
		  img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
		},
		{
		  img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
		},
		{
		  img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
		},
	  ];

	const caricaModello = () => {
		if (id!=='new') {
			api.get(`/modelli/${id}`).then(data=>{
				const modello = data[0];
				modello._action="e";
				setIdNazione(modello.idNazione);
				reset(modello);
			}, error=>{
				console.log("Impossibile caricare i dati", "error");
			});
		}
	}

	useEffect(() => {
		api.get('/rotabili/classi').then(data=>{
			setTipiClassi(data);
		});
		api.get('/rotabili/tipi').then(data=>{
			setTipiRotabili(data);
		});
		api.get('/produttori').then(data=>{
			setTipiProduttori(data);
		});
		api.get('/scale').then(data=>{
			setTipiScale(data);
		});
		api.get('/alimentazione').then(data=>{
			setTipiAlimentazioni(data);
		});
		api.get('/nazioni').then(data=>{
			setTipiNazioni(data);
		});
		api.get('/epoche').then(data=>{
			setTipiEpoche(data);
		});
		api.get('/fornitori').then(data=>{
			setTipiFornitori(data);
		});
		api.get('/condizioni').then(data=>{
			setTipiCondizioni(data);
		});
		api.get('/motori').then(data=>{
			setTipMotori(data);
		});
		api.get('/decoder').then(data=>{
			setTipiDecoder(data);
		});
  	}, []);

	const handelChangeNazione = (event) => {
		console.log(idNazione);
		console.log("idNazione Change");
		const value = event.target.value;
		console.log(value);
		setIdNazione(value);
	};

	useEffect(() => {
		if (idNazione !== undefined && idNazione !== null && idNazione !== "") {
			// api.get("/nazioni/"+idNazione+"/ferrovie").then(data=>{
			api.get("/ferrovie").then(data=>{
				setTipiFerrovie(data);
			});
		}
  	}, [idNazione]);

	useEffect(() => {
		setLoaderOpen(true);
	    setTitle('Dettaglio del modello');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaModello();
  	}, [setTitle, setContextCommands]);

	useEffect(()=>{
		if (id==='new') {
			setValue('_action', 'n');
			switch(tipo) {
				case 'collezione':
					setValue('collezione', 1);
					break;
				case 'venduti':
					setValue('venduto', 1);
					break;
				case 'wishlist':
					setValue('wishlist', 1);
					break;
				case 'condivisi':
					setValue('condiviso', 1);
					break;
				default:
					break;
			  }
			setLoaderOpen(false);
		} else {
			api.get(`/modelli/${id}`).then(data=>{
				if (data.length!==1) {
					alert('Errore nel caricamento dei dati');
				} else {
					const modello = data[0];
					modello._action="e";
				}
				setLoaderOpen(false);
			});
		}
	}, [id, setValue, tipo]);

	const salva = useCallback(data=>{
		var array = [];
		array.push(data);
		const json = nullZLSTabella(array);
		api.post("/modelli", json).then(r=>{
			pushNotification("Dati salvati", "success");
			navigate("/modelli/"+tipo+"/"+r);
		}, error=>{
			pushNotification("Errore nel salvataggio del cliente", "error");
		});
	}, [pushNotification, navigate]);

	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="backspace" onClick={ ()=>navigate("/modelli/"+tipo) }>Indietro</ContextCommand>,
			<ContextCommand key="2" icon="floppy" onClick={ handleSubmit(data=>salva(data)) }>Salva</ContextCommand>
		]);
	}, [setContextCommands, handleSubmit, navigate, salva] );

	return (
		<Box sx={{ height: '100%', width: '100%' }}>
			<Loader open={isLoaderOpen} />
			<Grid container spacing={2}>
				<Grid container xs={12}>
					<FormCheckbox xs={6} md={3} control={control} name="collezione" label="Nella collezione" icon={<TrainOutlinedIcon />} checkedIcon={<TrainIcon />}/>
					<FormCheckbox xs={6} md={3} control={control} name="condiviso" label="Condiviso" icon={<PeopleOutlineIcon />} checkedIcon={<PeopleIcon />}/>
					<FormCheckbox xs={6} md={3} control={control} name="venduto" label="Venduto" icon={<SellOutlinedIcon />} checkedIcon={<SellIcon />}/>
					<FormCheckbox xs={6} md={3} control={control} name="wishlist" label="Nella lista dei desideri" icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
				</Grid>

				<FormTextField xs={3} md={1} size="small" control={control} name="id" label="ID" type="text" disabled/>
				<FormTextField xs={9} md={5} size="small" placeholder="La descrizione del modello" control={control} name="descrizione" label="Modello" type="text"/>
				<FormTextField xs={6} md={3} size="small" placeholder="Il numero d'articolo" control={control} name="articolo" label="Articolo" type="text"/>
				<FormTextField xs={6} md={3} size="small" placeholder="Il numero di serie" control={control} name="numeroSerie" label="Num. di serie" type="text"/>

				<FormTextField xs={12} md={12} size="small" control={control} name="fotoModello" label="Fotografie" type="text"/>
				{/* <FormTextField xs={6} md={6} size="small" control={control} name="fotoPrototipo" label="fotoPrototipo" type="text"/> */}

				<Grid container xs={12} md={12}>
					<ImageList
					sx={{ width: '100%', height: 250, paddingLeft: '16px' }}
					variant="quilted"
					cols={3}
					rowHeight={240}
					>
					{itemData.map((item) => (
						<ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
						<img
							{...srcset(item.img, 240, item.rows, item.cols)}
							alt={item.title}
							loading="lazy"
						/>
						</ImageListItem>
					))}
					</ImageList>
				</Grid>

				<FormSelect xs={6} md={6} size="small" control={control} name='idClasseRotabile' label="Classe rotabile" options={tipiClassi} valueField='id' labelField='classeRotabile' />
				<FormSelect xs={6} md={6} size="small" control={control} name='idTipoRotabile' label="Tipo rotabile" options={tipiRotabili} valueField='id' labelField='tipoRotabile' />

				<FormSelect xs={6} md={6} size="small" control={control} name='idProduttore' label="Produttore" options={tipiProduttori} valueField='id' labelField='produttore' />
				<FormSelect xs={6} md={6} size="small" control={control} name='idScala' label="Scala" options={tipiScale} valueField='id' labelField='descrizioneScala' />

				<FormSelect xs={4} md={4} size="small" control={control} name='idAlimentazione' label="Alimentazione" options={tipiAlimentazioni} valueField='id' labelField='alimentazione' />
				<FormTextField xs={4} md={4} size="small" control={control} name="gruppo" label="Gruppo" type="text"/>
				<FormTextField xs={4} md={4} size="small" control={control} name="gruppoDRG" label="Gruppo DRG" type="text"/>

				<FormSelect xs={6} md={6} size="small" control={control} name='idNazione' label="Nazione" options={tipiNazioni} valueField='id' labelField='nazione' onChange={handelChangeNazione} />
				<FormSelect xs={6} md={6} size="small" control={control} name='idFerrovia' label="Ferrovia" options={tipiFerrovie} valueField='id' labelField='descrizioneFerrovia' />

				<FormSelect xs={6} md={6} size="small" control={control} name='idEpoca' label="Epoca" options={tipiEpoche} valueField='id' labelField='descrizioneEpoca' />
				<FormTextField xs={6} md={6} size="small" control={control} name="idEpocaDettaglio" label="Epoca dettaglio" type="text"/>

				<FormTextField xs={6} md={4} size="small" control={control} name="sub" label="Sub" type="text"/>
				<FormTextField xs={6} md={4} size="small" control={control} name="deposito" label="Deposito" type="text"/>
				<FormTextField xs={12} md={4} size="small" control={control} name="annoProd" label="Anno di produzione" type="text"/>
				
				<FormSelect xs={6} md={6} size="small" control={control} name='idFornitore' label="Fornitore" options={tipiFornitori} valueField='id' labelField='fornitore' />
				<FormSelect xs={6} md={6} size="small" control={control} name='idCondizioni' label="Condizione" options={tipiCondizioni} valueField='id' labelField='condizioni' />

				<FormTextField xs={6} md={6} size="small" control={control} name="acquistoData" label="Data d'acquisto" type="date"/>
				<FormTextField xs={6} md={6} size="small" control={control} name="acquistoPrezzo" label="Prezzo d'acquisto" type="number"/>
				<FormTextField xs={6} md={6} size="small" control={control} name="garanziaAnni" label="Anni in garanzia" type="number"/>
				<FormTextField xs={6} md={6} size="small" control={control} name="valoreAttuale" label="Valore attuale" type="number"/>

				<FormTextField xs={6} md={3} size="small" control={control} name="lunghezza" label="Lunghezza" type="number"/>
				<FormTextField xs={6} md={3} size="small" control={control} name="potenza" label="Potenza" type="number"/>
				<FormTextField xs={6} md={3} size="small" control={control} name="peso" label="Peso" type="number"/>
				<FormTextField xs={6} md={3} size="small" control={control} name="velocita" label="Velocita" type="number"/>

				<FormSelect xs={4} md={4} size="small" control={control} name='idMotore' label="Motore" options={tipiMotori} valueField='id' labelField='motore' />
				<FormSelect xs={4} md={4} size="small" control={control} name='idDecoder' label="Decoder" options={tipiDecoder} valueField='id' labelField='decoder' />
				<FormTextField xs={4} md={4} size="small" control={control} name="indirizzo" label="Indirizzo" type="text"/>

				<FormTextField xs={6} md={6} size="small" control={control} name="sound" label="Sound" type="text"/>
				<FormTextField xs={6} md={6} size="small" control={control} name="fumo" label="Fumo" type="text"/>

				<FormTextField xs={6} md={6} size="small" control={control} name="servizio" label="Servizio" type="text"/>
				<FormTextField xs={6} md={6} size="small" control={control} name="numeroServizio" label="Numero di servizio" type="text"/>

				<FormTextField xs={6} md={6} size="small" control={control} name="note" label="Note" type="text"/>
				<FormTextField xs={6} md={6} size="small" control={control} name="switch" label="Switch" type="text"/>

				<FormTextField xs={12} md={12} size="small" control={control} name="quantita" label="Quantità" type="number"/>

				{/* <FormTextField xs={4} md={4} size="small" control={control} name="rodiggio" label="Rodiggio" type="text"/> */}
				{/* <FormTextField xs={6} md={6} size="small" control={control} name="lunghPrototipo" label="lunghPrototipo" type="number"/> */}
			</Grid>
		</Box>
	);
}
