import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import ContextCommand from '../components/ContextCommand';
import useNotification from './../components/useNotification';
import api from '../lib/api';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

export default function Modelli({tipo, setTitle, setContextCommands}) {
	const [ rows, setRows ] = useState([]);
	const [ selectedIDs, setSelectedIDs ] = useState([]);
	const navigate = useNavigate();
	const { pushNotification } = useNotification();

	const columns=[
		// { field: 'id', headerName: 'ID', width: 90, type:'number' },
		{ field: 'descrizione', headerName: 'Descrizione', width: 550 },
		{ field: 'articolo', headerName: 'Articolo', flex:true },
		{ field: 'numeroSerie', headerName: 'Numero di serie', flex:true },
		{ field: 'descrizioneEpoca', headerName: 'Epoca', flex:true },
	]

	const caricaRows = () => {
		switch(tipo) {
			case 'collezione':
				api.get("/modelli/elenco?collezione=1").then(data=>{
					setRows(data);
				}, error=>{
					console.log("Impossibile caricare i dati", "error");
				});
				break;
			case 'venduti':
				api.get("/modelli/elenco?venduto=1").then(data=>{
					setRows(data);
				}, error=>{
					console.log("Impossibile caricare i dati", "error");
				});
				break;
			case 'wishlist':
				api.get("/modelli/elenco?wishlist=1").then(data=>{
					setRows(data);
				}, error=>{
					console.log("Impossibile caricare i dati", "error");
				});
				break;
			case 'condivisi':
				api.get("/modelli/elenco?condiviso=1").then(data=>{
					setRows(data);
				}, error=>{
					console.log("Impossibile caricare i dati", "error");
				});
				break;
			default:
				break;
		  }
	}

	useEffect(() => {
		switch(tipo) {
			case 'collezione':
				setTitle('Collezione');
				break;
			case 'venduti':
				setTitle('Modelli venduti');
				break;
			case 'wishlist':
				setTitle('Modelli desiderati');
				break;
			case 'condivisi':
				setTitle('Modelli condivisi');
				break;
			default:
				setTitle('Modelli');
				break;
		  }
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaRows();
	}, [tipo, setTitle, setContextCommands]);

	const cancella = useCallback(() => {
		selectedIDs.map(function(id, row){
			api.delete("/modelli/"+id, []).then(r=>{
				pushNotification("Dati cancellati", "success");
				caricaRows();
				navigate("/modelli/"+tipo);
			}, error=>{
				pushNotification("Errore nella cancellazione", "error");
			});
			return true;
		})
		setSelectedIDs([]);
	}, [selectedIDs, pushNotification, navigate]);

	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="plus" onClick={ ()=>navigate("/modelli/"+tipo+"/new") }>Aggiungi</ContextCommand>,
			<ContextCommand key="2" icon="delete" onClick={ cancella }>Cancella</ContextCommand>,
		]);
	}, [setContextCommands, navigate, cancella] );

	const rowDettaglio = (params) => {
		navigate(encodeURI("/modelli/"+tipo+"/"+params.id));
	};

	const handleSelectionChange = (selection) => {
		setSelectedIDs(selection);
	};

	return (
		<Box
			sx={{ height: '100%', width: '100%' }}
			noValidate
			autoComplete="off"
		>
			<p>
				Qui, hai il controllo totale sui tuoi modelli. Puoi gestirli come preferisci, modificarli o crearne di nuovi.<br/>
				Sfrutta al massimo le funzionalità messe a tua disposizione e dai libero sfogo alla tua creatività!
			</p>
			<DataGrid
			rows={rows}
			columns={columns}
			initialState={{
				pagination: {
				paginationModel: {
					pageSize: 15,
				},
				},
			}}
			pageSizeOptions={[15]}
			checkboxSelection
			onRowSelectionModelChange={handleSelectionChange}
			disableRowSelectionOnClick
			onRowClick={rowDettaglio}
			/>
		</Box>
		);
}
