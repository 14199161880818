import React, { useState, useEffect, useCallback } from 'react';
import api from '../lib/api';
import { nullZLSTabella } from './../lib/dati';
import Loader from './../components/Loader';
import useNotification from './../components/useNotification';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ContextCommand from '../components/ContextCommand';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormCheckbox from '../components/FormCheckbox';
import FormTextField from '../components/FormTextField';

export default function ConvogliDettaglio({setTitle, setContextCommands}) {
	const { id } = useParams();
	const [ isLoaderOpen, setLoaderOpen ] = useState(false);
	const { pushNotification } = useNotification();
	const { control, handleSubmit, /*watch, trigger, getValues,*/ setValue, reset, /*formState: { errors }*/ } = useForm({mode: 'all'});
	const navigate = useNavigate();

	const caricaConvoglio = () => {
		if (id!=='new') {
			api.get(`/convogli/${id}`).then(data=>{
				const convoglio = data[0];
				convoglio._action="e";
				reset(convoglio);
			}, error=>{
				console.log("Impossibile caricare i dati", "error");
			});
		}
	}

	useEffect(() => {
		setLoaderOpen(true);
	    setTitle('Dettaglio del convoglio');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaConvoglio();
  	}, [setTitle, setContextCommands]);

	useEffect(()=>{
		if (id==='new') {
			setValue('_action', 'n');
			setLoaderOpen(false);
		} else {
			api.get(`/convogli/${id}`).then(data=>{
				if (data.length!==1) {
					alert('Errore nel caricamento dei dati');
				} else {
					const convoglio = data[0];
					convoglio._action="e";
				}
				setLoaderOpen(false);
			});
		}
	}, [id, setValue]);

	const salva = useCallback(data=>{
		var array = [];
		array.push(data);
		const json = nullZLSTabella(array);
		api.post("/convogli", json).then(r=>{
			pushNotification("Dati salvati", "success");
			navigate("/convogli/"+r);
		}, error=>{
			pushNotification("Errore nel salvataggio del cliente", "error");
		});
	}, [pushNotification, navigate]);

	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="backspace" onClick={ ()=>navigate("/convogli") }>Indietro</ContextCommand>,
			<ContextCommand key="2" icon="floppy" onClick={ handleSubmit(data=>salva(data)) }>Salva</ContextCommand>
		]);
	}, [setContextCommands, handleSubmit, navigate, salva] );

	return (
		<Box sx={{ height: '100%', width: '100%' }}>
			<Loader open={isLoaderOpen} />
			<Grid container spacing={2}>
				<FormTextField xs={4} md={2} size="small" control={control} name="id" label="ID" type="text" disabled/>
				<FormTextField xs={8} md={10} size="small" placeholder="Il convoglio" control={control} name="convoglio" label="Convoglio" type="text"/>

				<FormTextField xs={12} md={12} size="small" placeholder="La descrizione del convoglio" control={control} name="descrizione" label="Descrizione" type="text"/>
				
				<FormCheckbox xs={6} md={6} control={control} name="proprietario" label="Proprietario?" />
				<FormCheckbox xs={6} md={6} control={control} name="condiviso" label="Condiviso?" />
			</Grid>
		</Box>
	);
}
