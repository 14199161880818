import React, { useState, useEffect } from 'react';
import api from '../lib/api';
import Loader from './../components/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ContextCommand from '../components/ContextCommand';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormSelect from '../components/FormSelect';
import FormTextField from '../components/FormTextField';
import TrainOutlinedIcon from '@mui/icons-material/TrainOutlined';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

export default function ModelliDettaglio({setTitle, setContextCommands}) {
	const { id } = useParams();
	const [ isLoaderOpen, setLoaderOpen ] = useState(false);
	const { control, handleSubmit, /*watch, trigger, getValues,*/ setValue, reset, /*formState: { errors }*/ } = useForm({mode: 'all'});
	const navigate = useNavigate();
	const [idNazione, setIdNazione] = useState("");
	const [tipiClassi, setTipiClassi] = useState([]);
	const [tipiRotabili, setTipiRotabili] = useState([]);
	const [tipiProduttori, setTipiProduttori] = useState([]);
	const [tipiScale, setTipiScale] = useState([]);
	const [tipiAlimentazioni, setTipiAlimentazioni] = useState([]);
	const [tipiNazioni, setTipiNazioni] = useState([]);
	const [tipiFerrovie, setTipiFerrovie] = useState([]);
	const [tipiEpoche, setTipiEpoche] = useState([]);
	const [tipiMotori, setTipMotori] = useState([]);
	const [tipiDecoder, setTipiDecoder] = useState([]);

	function srcset(image, size, rows = 1, cols = 1) {
		return {
		  src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
		  srcSet: `${image}?w=${size * cols}&h=${
			size * rows
		  }&fit=crop&auto=format&dpr=2 2x`,
		};
	  }

	const itemData = [
		{
		  img: '/var/www/vhosts/apertoladomenica.com/uploads/1/1/1.jpg',
		},
		{
		  img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
		},
		{
		  img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
		},
		{
		  img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
		},
		{
		  img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
		},
		{
		  img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
		},
	  ];

	const caricaModello = () => {
		api.get(`/condivisioni/modelli/${id}`).then(data=>{
			const modello = data[0];
			setIdNazione(modello.idNazione);
			reset(modello);
		}, error=>{
			console.log("Impossibile caricare i dati", "error");
		});
	}

	useEffect(() => {
		api.get('/rotabili/classi').then(data=>{
			setTipiClassi(data);
		});
		api.get('/rotabili/tipi').then(data=>{
			setTipiRotabili(data);
		});
		api.get('/produttori').then(data=>{
			setTipiProduttori(data);
		});
		api.get('/scale').then(data=>{
			setTipiScale(data);
		});
		api.get('/alimentazione').then(data=>{
			setTipiAlimentazioni(data);
		});
		api.get('/nazioni').then(data=>{
			setTipiNazioni(data);
		});
		api.get('/epoche').then(data=>{
			setTipiEpoche(data);
		});
		api.get('/motori').then(data=>{
			setTipMotori(data);
		});
		api.get('/decoder').then(data=>{
			setTipiDecoder(data);
		});
  	}, []);

	useEffect(() => {
		setLoaderOpen(true);
	    setTitle('Dettaglio del modello condiviso');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaModello();
  	}, [setTitle, setContextCommands]);

	useEffect(()=>{
		api.get(`/condivisioni/modelli/${id}`).then(data=>{
			if (data.length!==1) {
				alert('Errore nel caricamento dei dati');
			} else {
				const modello = data[0];
			}
			setLoaderOpen(false);
		});
	}, [id, setValue]);

	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="backspace" onClick={ ()=>navigate("/condivisioni/modelli/") }>Indietro</ContextCommand>
		]);
	}, [setContextCommands, handleSubmit, navigate] );

	return (
		<Box sx={{ height: '100%', width: '100%' }}>
			<Loader open={isLoaderOpen} />
			<Grid container spacing={2}>

				<FormTextField xs={3} md={1} size="small" control={control} name="id" label="ID" type="text" disabled/>
				<FormTextField xs={9} md={5} size="small" placeholder="La descrizione del modello" control={control} name="descrizione" label="Modello" type="text" disabled/>
				<FormTextField xs={6} md={3} size="small" placeholder="Il numero d'articolo" control={control} name="articolo" label="Articolo" type="text" disabled/>
				<FormTextField xs={6} md={3} size="small" placeholder="Il numero di serie" control={control} name="numeroSerie" label="Num. di serie" type="text" disabled/>

				<FormTextField xs={12} md={12} size="small" control={control} name="fotoModello" label="Fotografie" type="text" disabled/>

				<Grid container xs={12} md={12}>
					<ImageList
					sx={{ width: '100%', height: 250, paddingLeft: '16px' }}
					variant="quilted"
					cols={3}
					rowHeight={240}
					>
					{itemData.map((item) => (
						<ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
						<img
							{...srcset(item.img, 240, item.rows, item.cols)}
							alt={item.title}
							loading="lazy"
						/>
						</ImageListItem>
					))}
					</ImageList>
				</Grid>

				<FormSelect xs={6} md={6} size="small" control={control} name='idClasseRotabile' label="Classe rotabile" options={tipiClassi} valueField='id' labelField='classeRotabile' disabled/>
				<FormSelect xs={6} md={6} size="small" control={control} name='idTipoRotabile' label="Tipo rotabile" options={tipiRotabili} valueField='id' labelField='tipoRotabile' disabled/>

				<FormSelect xs={6} md={6} size="small" control={control} name='idProduttore' label="Produttore" options={tipiProduttori} valueField='id' labelField='produttore' disabled/>
				<FormSelect xs={6} md={6} size="small" control={control} name='idScala' label="Scala" options={tipiScale} valueField='id' labelField='descrizioneScala' disabled/>

				<FormSelect xs={4} md={4} size="small" control={control} name='idAlimentazione' label="Alimentazione" options={tipiAlimentazioni} valueField='id' labelField='alimentazione' disabled/>
				<FormTextField xs={4} md={4} size="small" control={control} name="gruppo" label="Gruppo" type="text" disabled/>
				<FormTextField xs={4} md={4} size="small" control={control} name="gruppoDRG" label="Gruppo DRG" type="text" disabled/>

				<FormSelect xs={6} md={3} size="small" control={control} name='idNazione' label="Nazione" options={tipiNazioni} valueField='id' labelField='nazione' disabled/>
				<FormSelect xs={6} md={3} size="small" control={control} name='idFerrovia' label="Ferrovia" options={tipiFerrovie} valueField='id' labelField='descrizioneFerrovia' disabled/>
				<FormSelect xs={6} md={3} size="small" control={control} name='idEpoca' label="Epoca" options={tipiEpoche} valueField='id' labelField='descrizioneEpoca' disabled/>
				<FormTextField xs={6} md={3} size="small" control={control} name="idEpocaDettaglio" label="Epoca dettaglio" type="text" disabled/>

				<FormTextField xs={6} md={3} size="small" control={control} name="lunghezza" label="Lunghezza" type="number" disabled/>
				<FormTextField xs={6} md={3} size="small" control={control} name="potenza" label="Potenza" type="number" disabled/>
				<FormTextField xs={6} md={3} size="small" control={control} name="peso" label="Peso" type="number" disabled/>
				<FormTextField xs={6} md={3} size="small" control={control} name="velocita" label="Velocita" type="number" disabled/>

				<FormSelect xs={6} md={6} size="small" control={control} name='idMotore' label="Motore" options={tipiMotori} valueField='id' labelField='motore' disabled/>
				<FormSelect xs={6} md={6} size="small" control={control} name='idDecoder' label="Decoder" options={tipiDecoder} valueField='id' labelField='decoder' disabled/>

				<FormTextField xs={6} md={6} size="small" control={control} name="servizio" label="Servizio" type="text" disabled/>
				<FormTextField xs={6} md={6} size="small" control={control} name="numeroServizio" label="Numero di servizio" type="text" disabled/>

				

				<Grid container xs={12} md={6} sx={{ paddingLeft: '16px' }}>
					<Button variant="contained" startIcon={<TrainOutlinedIcon />} fullWidth>COPIA nella tue collezione</Button>
				</Grid>
				<Grid container xs={12} md={6} sx={{ paddingLeft: '16px' }}>
					<Button variant="contained" startIcon={<TrainOutlinedIcon />} fullWidth>COPIA nella tua lista dei desideri</Button>
				</Grid>
			</Grid>
		</Box>
	);
}
