import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import api from '../lib/api';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

export default function Modelli({setTitle, setContextCommands}) {
	const [ rows, setRows ] = useState([]);
	const navigate = useNavigate();

	const columns=[
		// { field: 'id', headerName: 'ID', width: 90, type:'number' },
		{ field: 'descrizione', headerName: 'Descrizione', width: 550 },
		{ field: 'articolo', headerName: 'Articolo', flex:true },
		{ field: 'numeroSerie', headerName: 'Numero di serie', flex:true },
		{ field: 'descrizioneEpoca', headerName: 'Epoca', flex:true },
	]

	const caricaRows = () => {
		api.get("/condivisioni/modelli/elenco").then(data=>{
			setRows(data);
		}, error=>{
			console.log("Impossibile caricare i dati", "error");
		});
	}

	useEffect(() => {
		setTitle('Condivisione modelli');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaRows();
	}, [setTitle, setContextCommands]);


	const rowDettaglio = (params) => {
		navigate(encodeURI("/condivisioni/modelli/"+params.id));
	};

	return (
		<Box
			sx={{ height: '100%', width: '100%' }}
			noValidate
			autoComplete="off"
		>
			<p>
				In questa sezione puoi visualizzare i modelli condivisi dagli altri utenti.<br/>
				Puoi copiarli nella tua collezzione.
			</p>
			<DataGrid
			rows={rows}
			columns={columns}
			initialState={{
				pagination: {
				paginationModel: {
					pageSize: 15,
				},
				},
			}}
			pageSizeOptions={[15]}
			disableRowSelectionOnClick
			onRowClick={rowDettaglio}
			/>
		</Box>
		);
}
