import React, { useEffect } from 'react';
import Box from '@mui/material/Box';

export default function Home({setTitle, setContextCommands}) {

	useEffect(() => {
	    setTitle('Home');
		const contextCommands=[];
		setContextCommands(contextCommands);
  }, [setTitle, setContextCommands]);

	return (
		<Box sx={{ height: '100%', width: '100%' }}>
			<h1>Model railway management</h1>
			<p>Questo software di gestione per il modellismo ferroviario è una potente risorsa per gli amanti dei treni in scala.</p>
			<p>Quest'applicazione offre una serie di funzionalità che aiutano a organizzare e gestire efficacemente l'aspetto operativo del modellismo ferroviario.<br/>
			Ad esempio, consentono di tenere traccia delle locomotive, dei vagoni e degli accessori, facilitando l'inventario e la manutenzione del materiale rotabile.</p>
			<p>Inoltre, questo software include anche caratteristiche avanzate come il controllo delle funzioni dei treni, nonché la possibilità di registrare manutenzioni specifiche per rendere l'esperienza ancora più coinvolgente.</p>
			<p>In questa piattaforma user-friendly di gestione per il modellismo ferroviario, rendiamo facile e intuitivo il processo di gestione dei tuoi modelli fornendo agli appassionati uno strumento indispensabile per creare un'esperienza ferroviaria ricca e soddisfacente.</p>
			<p>Sfrutta al massimo questa piattaforma per tenere traccia di tutte le informazioni importanti e assicurarti che tutto sia in ordine. Non esitare a esplorare le diverse opzioni disponibili per semplificare il processo di gestione e ottenere risultati ottimali. Buon lavoro!</p>
		</Box>
		);
}
