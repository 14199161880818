import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import ContextCommand from '../components/ContextCommand';
import useNotification from './../components/useNotification';
import api from '../lib/api';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

export default function Convogli({setTitle, setContextCommands}) {
	const [ rows, setRows ] = useState([]);
	const [ selectedIDs, setSelectedIDs ] = useState([]);
	const navigate = useNavigate();
	const { pushNotification } = useNotification();

	const columns=[
		// { field: 'id', headerName: 'ID', width: 90, type:'number' },
		{ field: 'convoglio', headerName: 'Convoglio', flex:true },
		{ field: 'descrizione', headerName: 'Descrizione', flex:true},
		{ field: 'proprietario', headerName: 'Proprietario', width: 100, type: 'boolean', valueGetter: params => {if (params.value==="1") return true; return false} },
		{ field: 'condiviso', headerName: 'Condiviso', width: 100, type: 'boolean', valueGetter: params => {if (params.value==="1") return true; return false} },
	]

	const caricaRows = () => {
		api.get("/convogli/elenco").then(data=>{
		  setRows(data);
		}, error=>{
		  console.log("Impossibile caricare i dati", "error");
		});
	}

	useEffect(() => {
	    setTitle('Convogli');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaRows();
	}, [setTitle, setContextCommands]);

	const cancella = useCallback(() => {
		console.log(selectedIDs);
		selectedIDs.map(function(id, row){
			api.delete("/convogli/"+id, []).then(r=>{
				pushNotification("Dati cancellati", "success");
				caricaRows();
				navigate("/convogli/");
			}, error=>{
				pushNotification("Errore nella cancellazione", "error");
			});
			return true;
		})
		setSelectedIDs([]);
	}, [selectedIDs, pushNotification, navigate]);

	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="plus" onClick={ ()=>navigate("/convogli/new") }>Aggiungi</ContextCommand>,
			<ContextCommand key="2" icon="delete" onClick={ cancella }>Cancella</ContextCommand>,
		]);
	}, [setContextCommands, navigate, cancella] );

	const rowDettaglio = (params) => {
		navigate(encodeURI("/convogli/"+params.id));
	};

	const handleSelectionChange = (selection) => {
		setSelectedIDs(selection);
	};

	return (
		<Box
			sx={{ height: '100%', width: '100%' }}
			noValidate
			autoComplete="off"
		>
			<p>
				Qui hai la possibilità di inserire e gestire i tuoi convogli in modo semplice ed efficiente. Inoltre, potrai facilmente verificare se hai i modelli relativi a ciascun convoglio.
			</p>
			<DataGrid
			rows={rows}
			columns={columns}
			initialState={{
				pagination: {
				paginationModel: {
					pageSize: 15,
				},
				},
			}}
			pageSizeOptions={[15]}
			checkboxSelection
			onRowSelectionModelChange={handleSelectionChange}
			disableRowSelectionOnClick
			onRowClick={rowDettaglio}
			/>
		</Box>
		);
}
